.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* @charset "utf-8"; */

/*追加
---------------------------------------------------------------------------*/
/*ラインと、全体セクションについて
---------------------------------------------------------------------------*/
.line {
  width: 0.15%;
  height: 100%;
  background-color: white;
  margin: 0 auto;
}

.section {
  margin-top: 5%;
  margin-bottom: 10%;
}

.top_text {
  margin-left: 12vw;
}

.top_text.works {
  margin-left: 3vw;
}

/*mainブロック内のh2タグ*/
main .top_text h2 {
  position: relative;
  letter-spacing: 0.03em;
  display: inline;
  text-decoration: underline 2px #1a1a1a;
  text-underline-offset: 4px;
  color: #1a1a1a;
  font-size: 3vw;
  width: 80vw;
}
main h2.WH {
  position: relative;
  letter-spacing: 0.03em;
  display: inline;
  text-decoration: underline 2px #ffffff;
  text-underline-offset: 7px;
  color: #ffffff;
  font-size: 3vw;
}

.WH {
  color: #ffffff;
}

/*mainブロック内のh3タグ*/
main h3 {
  margin: -1.5vw 0 0 0;
  color: #1a1a1a;
  font-size: 1vw;
}

p {
  margin: 0;
}
/*Animation
---------------------------------------------------------------------------*/
.animation1 {
  opacity: 0;
  visibility: hidden;
  transition: 2s;
  transform: translateY(30px);
}
.active1 {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.animation2 {
  opacity: 0;
  visibility: hidden;
  transition: 2s;
  transform: translateY(30px);
}
.active2 {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}
.animation3 {
  opacity: 0;
  visibility: hidden;
  transition: 2s;
  transform: translateY(30px);
}
.active3 {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.animation4 {
  opacity: 0;
  visibility: hidden;
  transition: 2s;
  transform: translateY(30px);
}
.active4 {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.animationSlow {
  opacity: 0;
  visibility: hidden;
  transition: 3s;
  transform: translateY(30px);
}
.activeSlow {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}
.bg {
  margin-left: 50vw;
  margin-bottom: 10vw;
  height: 25vw;
}
.bg_Line {
  margin-top: -10vw;
  width: 0.1vw;
  height: 0;
  background: #ffffff;
}
.bg_Line_anime {
  animation: bar-animation 1s forwards;
}
.bg_lineArrow {
  margin: -4vw 0 0 1.1vw;
  width: 3vw;
  height: 5vw;
  transform: rotate(30deg);
  background-repeat: no-repeat;
  background-size: 0;
  background-position: left bottom, left top;
}
.bg_lineArrow_anime {
  animation: bar-animation2 0.5s linear forwards 1s;
  background-image: linear-gradient(00deg, white, white);
}
@keyframes bar-animation {
  0% {
    height: 0;
  }
  100% {
    height: 25vw;
  }
}
@keyframes bar-animation2 {
  0% {
    background-size: 0.1vw 0;
  }
  100% {
    background-size: 0.1vw 100%;
  }
}
/*Video
---------------------------------------------------------------------------*/
video {
  width: 100vw;
}

/*About
---------------------------------------------------------------------------*/
.white_back {
  background-color: #ffffff;
  width: 100vw;
  height: 30vw;
}
.About_img {
  margin: -20vw 0 0 48vw;
  width: 30vw;
  height: 30vw;
  display: flex;
  object-fit: cover;
}
.rotate {
  animation: rotate-anime 3s reverse ease-in-out;
}
@keyframes rotate-anime {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(540deg);
  }
}
.About_logo {
  position: absolute;
  margin: -21vw 0 0 45vw;
  width: 36vw;
}
.about_txt {
  padding-right: 4vw;
  font-size: 1.2vw;
  color: #111;
  line-height: 2.2vw;
  width: 47vw;
}
.about_logo_phone img {
  opacity: 0;
}

/*Our business
---------------------------------------------------------------------------*/
.black_back {
  background-color: #1a1a1a;
  height: 45vw;
}

.business_imges {
  margin-top: 3vw;
  text-align: center;
}
.business_section {
  display: inline;
}
.img_B {
  width: 23vw;
  margin-left: 0.5vw;
}
.move_create {
  margin-top: 1vw;
}
.HP {
  padding-top: 1vw;
  margin-top: 2vw;
}
.consal {
  padding-top: 2vw;
  margin-top: 4vw;
}
.out_sorce {
  padding-top: 3vw;
  margin-top: 6vw;
}

.tx_B {
  position: absolute;
  display: inline;
  color: white;
  font-size: 1.5vw;
  margin-top: 26vw;
  margin-left: -23vw;
  font-weight: bolder;
}
.p_B {
  position: absolute;
  display: inline;
  color: white;
  font-size: 1vw;
  margin-top: 29vw;
  margin-left: -23vw;
  width: 22vw;
  text-align: left;
  line-height: 1.5vw;
}

/*Works
---------------------------------------------------------------------------*/
.white_back_works {
  background-color: #ffffff;
  margin-left: 10%;
  width: 90vw;
  height: 25vw;
}
.top_text.works {
  margin-left: 2vw;
}
.slide:hover {
  transform: scale(1.05);
  transition: 0.5s;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -o-transition: 0.5s;
}
.slide-img {
  display: block;
  width: 28vw;
  height: 16vw;
  object-fit: cover;
}
ul.paginationPosition {
  margin-bottom: -2.2%;
  margin-left: -70vw;
}
button.paginationColor {
  background-color: #a7a7a7;
  width: 2.5vw;
  height: 3px;
  margin-right: 0.2vw;
  border-radius: 3px;
}
button.paginationColor.is-active {
  background-color: #1a1a1a;
  transform: scale(1);
}
li.splide__slide.is-active {
  border: 3px solid white !important;
}

/*Topics
---------------------------------------------------------------------------*/
.topics_main {
  margin-top: 5vw;
  margin-left: -12vw;
  text-align: center;
}
.topics {
  color: white;
  border-bottom: solid 1px rgba(255, 255, 255, 0.2);
  width: 40vw;
  height: 3vw;
  font-size: 1.2vw;
  text-align: left;
  margin: 1vw auto 0 auto;
}
.topicsTime {
  display: inline-block;
  width: 8vw;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
}
.topicsText:hover {
  opacity: 0.5;
}
.topicsDesc {
  display: inline-block;
  width: 32vw;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.topics_points {
  margin-top: 3vw;
  font-weight: bolder;
  color: rgba(255, 255, 255, 0.5);
  transform: rotate(90deg);
}
.topics_points:hover {
  opacity: 0.5;
}
/*Contact
---------------------------------------------------------------------------*/
.white_back_contact {
  background-color: #ffffff;
  margin-top: 10vw;
  margin-bottom: 15vw;
  width: 100vw;
  height: 20vw;
}
.contact_main {
  position: absolute;
  font-weight: lighter;
  font-size: 1.5vw;
  margin: 5vw auto 0 40vw;
  width: 20vw;
  text-align: center;
}
.contact_btn {
  position: absolute;
  background-color: #666;
  color: white;
  font-weight: lighter;
  font-size: 2.5vw;
  margin-top: 9vw;
  width: 12vw;
  margin-left: 40vw;
  text-align: center;
  padding: 0 4vw;
  border-radius: 10px;
}

/*子ページ
---------------------------------------------------------------------------*/
.header_back {
  width: 100vw;
  height: 3vw;
  background-color: #1a1a1a;
}

.child_copy {
  position: absolute;
  top: 15vw;
  left: 15vw;
  font-size: 4.5vw;
  color: rgb(255, 255, 255);
}

.child_top {
  width: 100vw;
}

.bar {
  color: white;
  margin-left: 12vw;
  padding: 0.3vw 0;
}
.bar_top_line {
  background-color: white;
  width: 100vw;
  height: 0.05vw;
}
.bar a {
  color: white;
}

.top_text_child {
  color: #1a1a1a;
  font-size: 2.5vw;
  margin-top: 5vw;
  padding-top: 2vw;
}

.top_text_child_h2 {
  position: relative;
  letter-spacing: 0.03em;
  display: inline;
  text-decoration: underline 2px #ffffff;
  text-underline-offset: 7px;
  color: #ffffff;
  font-size: 3vw;
}
.top_text_child_h3 {
  margin: -1.5vw 0 0 0;
  color: #ffffff;
  font-size: 1vw;
}

.top_text_child_h2BL {
  position: relative;
  letter-spacing: 0.03em;
  display: inline;
  text-decoration: underline 2px #1a1a1a;
  text-underline-offset: 7px;
  color: #1a1a1a;
  font-size: 3vw;
}
.top_text_child_h3BL {
  margin: -1.5vw 0 0 0;
  color: #1a1a1a;
  font-size: 1vw;
}
.top_text_child_p {
  font-size: 1vw;
  line-height: 1.8vw;
}

/*子ページ About
---------------------------------------------------------------------------*/
.white_back_about {
  margin-top: 5vw;
  background-color: #ffffff;
  width: 93vw;
  height: 39vw;
  padding-right: 2vw;
}

.child_about {
  color: #1a1a1a;
  font-size: 2.5vw;
  font-weight: bolder;
  margin-top: 5vw;
  padding-top: 2vw;
}

.child_p_about {
  font-weight: bolder;
  font-size: 1.1vw;
  padding-right: 3vw;
}
.company_main {
  margin-top: 8%;
  margin-left: -12vw;
  text-align: center;
}
.company {
  color: white;
  text-decoration: underline 1px rgba(255, 255, 255, 0.5);
  line-height: 5vw;
  text-underline-offset: 7px;
  font-size: 1.2vw;
}

/*子ページ Our Business
---------------------------------------------------------------------------*/
.white_back_business {
  margin: 0 auto;
  background-color: #ffffff;
  width: 90vw;
  height: auto;
}
.left_child {
  margin: 3vw 0 3vw 1vw;
  width: 50vw;
}
.right_child {
  margin: 3vw 0 3vw 39vw;
  width: 50vw;
}

.top_text_child_h2_business {
  position: relative;
  letter-spacing: 0.03em;
  text-decoration: underline 2px #1a1a1a;
  text-underline-offset: 7px;
  color: #1a1a1a;
  font-size: 2vw;
  font-weight: bold;
}
.inline_right {
  display: inline-block;
  position: absolute;
  margin-top: 12vw;
  padding-left: 1vw;
  width: 36vw;
  font-weight: bolder;
}
.inline_left {
  position: absolute;
  margin-top: -22vw;
  padding-left: 2vw;
  width: 36vw;
  font-weight: bolder;
}

/*子ページ Works
---------------------------------------------------------------------------*/
.white_back_child_works {
  padding: 1vw 0 10vw;
  background-color: #ffffff;
  width: 100vw;
  height: auto;
}
#works {
  margin-top: 3vw;
  margin-left: 3vw;
}
.works_boxText {
  display: inline-block;
  margin-right: 2vw;
}
.worksImg {
  cursor: pointer;
}
.worksImg:hover {
  opacity: 0.8;
}
.works_boxText img {
  margin: 1vw 0.5vw 0;
  width: 29vw;
  height: 20vw;
  /*トリミング*/
  object-fit: cover;
}
.worksHead {
  margin-top: 0.5vw;
  margin-left: 0.5vw;
  width: 29vw;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 1.2vw;
  font-weight: bolder;
  text-align: center;
}
.works_bar {
  background-color: #666;
  margin: 4vw auto 4vw;
  width: 15vw;
  height: 0.05vw;
}

/*子ページ Topics
---------------------------------------------------------------------------*/
.TopicsContact {
  height: 84px;
}
.white_back_topics {
  background-color: #ffffff;
  width: 100vw;
  height: 70vw;
}
.topics_main_child {
  margin-top: 5vw;
  padding-bottom: 10vw;
  margin-left: -12vw;
  display: inline;
  text-align: center;
}
.BL {
  color: #1a1a1a;
  border-bottom: solid 1px rgba(0, 0, 0, 0.5);
}

/*子ページ Contact
---------------------------------------------------------------------------*/
.white_back_child_contact {
  background-color: #ffffff;
  width: 100vw;
  height: 80vw;
  margin-top: 0;
}

input {
  margin: 1vw 1vw 1vw 5vw;
  padding: 0.5vw;
  width: 35vw;
}

textarea {
  margin: 1vw 1vw 0.1vw 5vw;
  padding: 0.5vw;
  resize: vertical;
  width: 35vw;
  height: 10vw;
}

table {
  margin: -12vw auto 0 12vw;
}

.explain {
  position: absolute;
  margin: -3.3vw 0 0 42.1vw;
}

.go {
  margin: 5vw auto 0 47.5vw;
  font-size: 1.2vw;
  padding: 0 1vw 0;
  background-color: #666;
  color: white;
  border-radius: 5px;
}
th {
  vertical-align: middle;
}

.field {
  margin-top: 15vw;
}

.contact_table h3 {
  margin-top: 1.4vw;
  font-size: 0.8vw;
  color: red;
  vertical-align: middle;
}
.must {
  color: red;
  font-size: 12px;
}

/*詳細ページ(Works/Topics)
---------------------------------------------------------------------------*/
.ommision {
  margin-bottom: -10px;
  display: inline-block;
  width: 25vw;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
}
.videotopDetail {
  margin-top: 84px;
}
.workDetail {
  background-color: white;
  padding: 5vw 1vw 5vw 12vw;
  margin-left: 0;
}
.workDetail img {
  width: 40vw;
  margin-left: 18vw;
  margin-bottom: 3vw;
}
.topicDetail {
  background-color: white;
  padding: 3vw 1vw 5vw 12vw;
  margin-left: 0;
}
.headline {
  position: relative;
  color: #1a1a1a;
  font-size: 2vw;
  width: 78vw;
  line-height: 2.5vw;
}
.description {
  position: relative;
  color: #1a1a1a;
  font-size: 1.2vw;
  width: 78vw;
  line-height: 1.5vw;
}
.bar_top_line.BL {
  width: 100vw;
  margin-left: -12vw;
}

/*全体の設定
---------------------------------------------------------------------------*/
html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-size: 13px; /*基準となるフォントサイズ。下の方にある「画面幅900px以上」で基準を大きなサイズに再設定しています。*/
}
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP&family=Sawarabi+Gothic&display=swap");
body {
  /* font-family: "BIZ UDPGothic", "ヒラギノ角ゴ Pro W3", "メイリオ", Meiryo, Osaka,
    "ＭＳ Ｐゴシック", "MS PGothic", sans-serif; */
  /*フォント種類*/
  font-family: "Hiragino Kaku Gothic Pro", "Noto Sans JP", sans-serif;
  -webkit-text-size-adjust: none;
  background: #1a1a1a; /*背景色*/
  color: #555; /*全体の文字色*/
  line-height: 2; /*行間*/
  overflow-x: hidden;
}

/*リセット*/
figure {
  margin: 0;
}
dd {
  margin: 0;
}
nav {
  margin: 0;
  padding: 0;
}

/*table全般の設定*/
table {
  border-collapse: collapse;
}

/*画像全般の設定*/
img {
  border: none;
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

/*videoタグ*/
video {
  max-width: 100%;
}

/*iframeタグ*/
iframe {
  width: 100%;
}

/*ul,olタグ*/
ul,
ol {
  margin-bottom: 30px;
}

/*リンクテキスト全般の設定
---------------------------------------------------------------------------*/
a {
  color: #57524b; /*文字色*/
  transition: 0.3s;
}

a:hover {
  opacity: 0.8; /*マウスオン時に80%の透明度にする*/
}

/*sectionタグと、詳細ページの共通設定
---------------------------------------------------------------------------*/
section,
main > article {
  margin: 0 auto;
  max-width: 1300px; /*最大幅。これ以上広がらない。*/
  padding: 2% 5%; /*ボックス内の余白*/
}
section {
  height: auto;
}

/*containerブロック
---------------------------------------------------------------------------*/
#container {
  height: 100%;
  display: flex; /*flexボックスを使う指定*/
  flex-direction: column; /*子要素を縦並びにする*/
  justify-content: space-between; /*並びかたの種類の指定*/
}

/*header（ロゴなどが入った最上段のブロック）
---------------------------------------------------------------------------*/
/*ヘッダーブロック*/
header {
  display: flex; /*flexボックスを使う指定*/
  align-items: center; /*垂直揃えの指定。上下中央に配置されるように。*/
  justify-content: flex-end; /*並びかたの種類の指定*/
  position: relative;
  z-index: 1;
  height: 42px;
  position: fixed;
}

/*ヘッダーのリンクテキストの文字色*/
header a {
  color: rgb(255, 255, 255);
}

/*ロゴ画像*/
header #logo {
  margin: 0;
  width: 180px; /*ロゴの幅*/
  position: absolute;
  left: 10%; /*ヘッダーの左からの配置場所の指定*/
  top: -10px; /*ヘッダーの上からの配置場所の指定*/
}

.copy {
  position: absolute;
  top: 15vw;
  left: 15vw;
  font-size: 4.5vw;
  color: rgb(255, 255, 255);
}

/*menubarブロック初期設定
---------------------------------------------------------------------------*/
header #menubar {
  position: absolute;
  right: 10%;
  top: 0px;
  font-weight: bold;
}
#menubar ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

/*メニュー１個あたりの設定*/
#menubar a {
  display: block;
  text-decoration: none;
  text-align: center; /*テキストを中央に*/
  letter-spacing: 0.1em; /*文字間隔を少しだけ広くする指定*/
  font-size: large;
}

/*小さな端末用の開閉ブロック設定
---------------------------------------------------------------------------*/
/*メニューブロック設定*/
.s #menubar.db {
  position: fixed;
  overflow: auto;
  z-index: 100;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  padding: 70px 10% 0 0; /*上下、左右へのブロック内の余白*/
  background: rgba(
    0,
    0,
    0,
    0.9
  ); /*背景色。0,0,0は黒の事で0.9は色が90%出た状態の事。*/
  color: #fff; /*文字色*/
  animation: animation1 0.2s both; /*animation.cssの、animation1を実行する。0.2sは0.2秒の事。*/
  text-align: center;
}

/*メニュー１個あたりの設定*/
.s #menubar.db a {
  color: #fff; /*文字色*/
  padding: 20px; /*メニュー内の余白*/
}

/*ドロップダウンメニュー
---------------------------------------------------------------------------*/
/*ドロップダウンを非表示にしておく*/
#menubar .ddmenu_parent ul {
  display: none;
}

/*ドロップダウンメニューを持つ親に矢印アイコンをつける設定*/
a.ddmenu::before {
  font-family: "Font Awesome 5 Free"; /*Font Awesomeを使う指定*/
  content: "\f103"; /*使いたいアイコン名をここで指定。Font Awesomeに記載されています。詳しくは当テンプレートのマニュアルを読んで下さい。*/
  font-weight: bold; /*この手の設定がないとアイコンが出ない場合があります*/
  margin-right: 0.5em; /*アイコンとテキストとの間に空けるスペース*/
}

/*ドロップダウンを持つ親のマウスオン時にリンク用のカーソルでなくデフォルトの矢印を出す*/
a.ddmenu {
  cursor: default;
}

/*ドロップダウンメニュー１個あたりの設定*/
.ddmenu_parent ul a {
  background: rgba(
    0,
    0,
    0,
    0.8
  ); /*背景色。0,0,0は黒の事で0.8は色が80%出た状態の事。*/
}

/*３本バー（ハンバーガー）アイコン設定
---------------------------------------------------------------------------*/
/*開閉用のスタイル*/
#menubar_hdr.db {
  display: flex;
}
#menubar_hdr.dn {
  display: none;
}

/*３本バーを囲むブロック*/
#menubar_hdr {
  position: fixed;
  z-index: 101;
  cursor: pointer;
  right: 3%; /*左からの配置場所指定*/
  top: 0px; /*上からの配置場所指定*/
  padding: 16px 14px; /*上下、左右への余白*/
  width: 52px; /*幅（３本バーが出ている場合の幅になります）*/
  height: 52px; /*高さ*/
  display: flex; /*flexボックスを使う指定*/
  flex-direction: column; /*子要素（３本バー）を縦並びにする*/
  justify-content: space-between; /*並びかたの種類の指定*/
  order: 0; /*表示させる順番。「#menubar_hdr」「#logo」「#header-icon」それぞれに指定しており、数字の「小さな順」に左から並びます。*/
  background: rgba(0, 0, 0, 0.5); /*背景色*/
}

/*バー１本あたりの設定*/
#menubar_hdr span {
  display: block;
  transition: 0.3s; /*アニメーションにかける時間。0.3秒。*/
  border-top: 2px solid #fff; /*線の幅、線種、色*/
}

/*×印が出ている状態の設定。※１本目および２本目のバーの共通設定。*/
#menubar_hdr.ham span:nth-of-type(1),
#menubar_hdr.ham span:nth-of-type(3) {
  transform-origin: center center; /*変形の起点。センターに。*/
  width: 26px; /*バーの幅*/
}

/*×印が出ている状態の設定。※１本目のバー。*/
#menubar_hdr.ham span:nth-of-type(1) {
  transform: rotate(45deg) translate(6px, 7px); /*回転45°と、X軸Y軸への移動距離の指定*/
}

/*×印が出ている状態の設定。※３本目のバー。*/
#menubar_hdr.ham span:nth-of-type(3) {
  transform: rotate(-45deg) translate(6px, -7px); /*回転-45°と、X軸Y軸への移動距離の指定*/
}

/*×印が出ている状態の設定。※２本目のバー。*/
#menubar_hdr.ham span:nth-of-type(2) {
  display: none; /*２本目は使わないので非表示にする*/
}

/*mainブロック設定
---------------------------------------------------------------------------*/

main {
  margin-top: -30px;
}

main h2 .uline::before {
  content: "";
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 3px; /*ラインの高さ。上の「border-bottom」と「bottom」の３ヶ所の数字部分を合わせる*/
  background: #111; /*アニメーション時のラインの色*/
  transition: 1s 0.5s; /*1sはアニメーションの実行時間は1秒。0.5秒遅れてスタートする指定。*/
  transform: scaleX(0); /*幅。最初は0にして見えなくしておく。*/
  transform-origin: left top; /*線の出現起点が左からになるように。中央からの出現がよければこの１行削除。*/
}
main h2.linestyle .uline::before {
  transform: scaleX(1);
}

/*mainブロックのh3タグの冒頭の装飾設定*/
main h3::before {
  display: inline-block;
  /* content: "■";			この記号を表示させる */
  transform: scale(0.5); /*実寸の50%に縮小する指定*/
  padding-right: 10px; /*記号とテキストとの間にとる余白*/
  margin-left: -0.4em; /*少しだけ左側に移動する*/
}

/*フッター設定
---------------------------------------------------------------------------*/
footer small {
  font-size: 100%;
}

footer {
  font-size: 0.7rem; /*文字サイズ*/
  text-align: center; /*内容をセンタリング*/
  padding: 20px; /*ボックス内の余白*/
  background: #111; /*背景色*/
  color: #fff; /*文字色*/
}

/*リンクテキスト*/
footer a {
  text-decoration: none;
  color: #fff;
}

/*著作部分*/
footer .pr {
  display: block;
}

/*フッターメニュー
---------------------------------------------------------------------------*/
/*メニューブロック全体*/
#footermenu {
  margin: 0;
  padding: 0;
  margin-bottom: 15px; /*下に空けるスペース*/
}

/*メニュー１個あたり*/
#footermenu li {
  display: inline-block; /*横並びにする*/
  padding: 0 10px; /*上下、左右への余白*/
  font-size: 1.2em;
}

/*スライドショー（slickを使用）
---------------------------------------------------------------------------*/
.mainimg {
  position: relative;
}

/*丸いページナビボタン全体を囲むブロック*/
ul.slick-dots {
  margin: 0;
  padding: 0;
  line-height: 1;
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 10px; /*下からの配置場所指定*/
}

/*丸いページナビボタン１個あたりの設定*/
ul.slick-dots li {
  display: inline-block;
  margin: 0 10px;
  cursor: pointer;
}

/*buttonタグ*/
ul.slick-dots li button {
  border: none;
  padding: 0;
  display: block;
  text-indent: -9999px; /*デフォルトで文字が出るので画面の外に追い出す指定*/
  width: 12px; /*ボタンの幅*/
  height: 12px; /*ボタンの高さ*/
  border-radius: 50%; /*丸くする指定*/
  cursor: pointer; /*クリックで画像へジャンプするので、わかりやすいようhover時にpointerになるように。*/
  background: #fff; /*背景色。白。*/
}

/*buttonのアクティブ時（現在表示されている画像を示すボタン）*/
ul.slick-dots li.slick-active button {
  background: #ff0e0e; /*色*/
}

/*「お知らせ」ブロック
---------------------------------------------------------------------------*/
/*お知らせブロック*/
#new {
  margin: 0;
  display: flex; /*flexボックスを使う指定*/
  flex-wrap: wrap; /*折り返す指定*/
  padding: 0 20px; /*上下、左右へのボックス内の余白*/
}

/*日付(dt)、記事(dd)共通設定*/
#new dt,
#new dd {
  padding: 5px 0; /*上下、左右へのボックス内の余白*/
}

/*日付(dt)設定*/
#new dt {
  width: 8em; /*幅。8文字(em)分。※下の「900px以上」の端末用の設定に再設定があります。*/
}

/*日付の横のマーク（共通設定）*/
#new dt span {
  display: none; /*小さな端末では非表示にしておく。*/
}

/*記事(dd)設定*/
#new dd {
  width: calc(
    100% - 8em
  ); /*「8em」は上の「#new dt」のwidthの値です。※下の「900px以上」の端末用の設定に再設定があります。*/
}

/*listブロック
---------------------------------------------------------------------------*/
.list {
  margin-bottom: 30px; /*ボックス同士の上下間に空けるスペース*/
  padding: 20px; /*ボックス内の余白*/
  background: #fff; /*背景色*/
  color: #999; /*文字色*/
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.1); /*ボックスの影。右へ、下へ、ぼかし幅、0,0,0は黒の事で0.1は色が10%出た状態。*/
  position: relative;
}

/*ボックス内のh4タグ*/
.list h4 {
  margin: 0;
  color: #666; /*文字色*/
}
.list h4 a {
  color: #666; /*リンクテキストの文字色*/
}

/*ボックス内のpタグ*/
.list p {
  margin: 0;
  font-size: 0.8em; /*文字サイズを80%に*/
}

/*list内のNEWマーク*/
.list .new {
  font-size: 0.6em; /*文字サイズ*/
  background: #ff0000; /*背景色*/
  color: #fff; /*文字色*/
  width: 50px; /*幅*/
  line-height: 50px; /*高さ*/
  border-radius: 50%; /*角丸のサイズ。円形になります。*/
  text-align: center; /*テキストをセンタリング*/
  position: absolute;
  left: -10px; /*listブロックに対して左からの配置場所の指定*/
  top: -10px; /*listブロックに対して上からの配置場所の指定*/
  transform: rotate(-30deg); /*回転。まっすぐ表示させたいならこの１行を削除。*/
}

/*list内でのbtn*/
.list .btn {
  margin-top: 1em; /*ボタンの上に１文字分のスペースを空ける*/
}
.list .btn a {
  display: block;
}

/*FAQ
---------------------------------------------------------------------------*/
/*FAQボックス全体*/
.faq {
  padding: 0 5px; /*上下、左右へのボックス内の余白*/
}

/*質問*/
.faq dt {
  border-radius: 3px; /*枠を角丸にする指定*/
  margin-bottom: 20px; /*下に空けるスペース*/
  background: linear-gradient(#fff, #f7f7f7); /*背景グラデーション*/
  text-indent: -2em; /*テキストのインデント。質問が複数行になった際に、テキストの冒頭を揃える為に設定しています。*/
  padding: 5px 1em 5px 3em; /*ボックス内の余白。ここを変更する場合、上のtext-indentも調整します。*/
  border: 1px solid #e4e2d7; /*枠線の幅、線種、色*/
}

/*アイコン（Font Awesome）*/
.faq dt::before {
  font-family: "Font Awesome 5 Free"; /*Font Awesomeを使う指定*/
  content: "\f059"; /*使いたいアイコン名をここで指定。Font Awesomeに記載されています。詳しくは当テンプレートのマニュアルを読んで下さい。*/
  color: #770000; /*アイコンの色*/
  padding-right: 1em; /*アイコンとテキストの間の余白*/
}

/*回答*/
.faq dd {
  padding: 5px 1em 30px 3em; /*ボックス内の余白**/
}

/*opencloseを適用した要素のカーソル*/
.openclose {
  cursor: pointer; /*カーソルの形状。リンクと同じスタイルにしてクリックできると認識してもらう。*/
}

.faq dt span {
  text-indent: 0;
}

/*btnの設定
---------------------------------------------------------------------------*/
p.btn {
  margin: 0;
}

/*ボタンを囲むブロック*/
.btn {
  text-align: center; /*内容をセンタリング*/
}

/*ボタン*/
.btn a,
.btn input {
  display: inline-block;
  text-decoration: none;
  border: none;
  font-size: 1em;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); /*ボックスの影。右へ、下へ、ぼかし幅の順。0,0,0は黒の事で0.2は色が20%出た状態。*/
  background: linear-gradient(
    150deg,
    #ff0e0e,
    #770000
  ); /*背景グラデーション。#logoと合わせています。*/
  letter-spacing: 0.1em; /*文字間隔を少し広くする指定*/
  color: #fff; /*文字色*/
  transition: 0.3s; /*hoverまでにかける時間。0.3秒。*/
  padding: 1em 2em; /*余白*/
  margin: 0 auto 30px; /*ボタンの外側に空けるスペース。上、左右、下への順番。*/
}

/*ボタンのマウスオン時*/
.btn a:hover,
.btn input:hover {
  cursor: pointer; /*inputタグを使う場合に「手」のマークになるように。リンクと同じ表示になるようにという事です。*/
  opacity: 0.8; /*冒頭のリンクテキストのhoverと合わせました*/
  transform: scale(1.02); /*実寸の102%に拡大*/
}

/*listブロック内のボタン*/
.list .btn a {
  margin: 0;
}

/*テーブル
---------------------------------------------------------------------------*/
/*テーブル１行目に入った見出し部分（※caption）*/
.ta1 caption {
  font-weight: bold; /*太字に*/
  padding: 10px 5px; /*上下、左右へのボックス内の余白。基本的に数行下の「.ta1 th, .ta1 td」のpaddingと揃えておけばOKです。*/
  background: #eee; /*背景色*/
  margin-bottom: 15px; /*下に空けるスペース*/
}

/*ta1テーブルブロック設定*/
.ta1 {
  border-top: 1px solid #ccc; /*テーブルの一番上の線。幅、線種、色*/
  width: 100%;
  margin: 0 auto 30px; /*最後の「30px」がテーブルの下に空けるスペースです*/
}

/*tr（１行分）タグ設定*/
.ta1 tr {
  border-bottom: 1px solid #ccc; /*テーブルの下線。幅、線種、色*/
}

/*th（左側）、td（右側）の共通設定*/
.ta1 th,
.ta1 td {
  padding: 10px 5px; /*上下、左右へのボックス内の余白*。基本的に数行上の「.ta1 caption」のpaddingと揃えておけばOKです。*/
  word-break: break-all; /*英語などのテキストを改行で自動的に折り返す設定。これがないと、テーブルを突き抜けて表示される場合があります。*/
}

/*th（左側）のみの設定*/
.ta1 th {
  width: 30%; /*幅*/
  text-align: left; /*左よせにする*/
}

/*背景色パターン
---------------------------------------------------------------------------*/

/*PAGE TOP（↑）設定
---------------------------------------------------------------------------*/
.pagetop-show {
  display: block;
}

/*ボタンの設定*/
.pagetop a {
  display: block;
  text-decoration: none;
  text-align: center;
  z-index: 99;
  position: fixed; /*スクロールに追従しない(固定で表示)為の設定*/
  right: 20px; /*右からの配置場所指定*/
  bottom: 20px; /*下からの配置場所指定*/
  color: #fff; /*文字色*/
  font-size: 1.5em; /*文字サイズ*/
  background: rgba(
    0,
    0,
    0,
    0.2
  ); /*背景色。0,0,0は黒の事で0.2は色が20%出た状態。*/
  width: 60px; /*幅*/
  line-height: 60px; /*高さ*/
  border-radius: 50%; /*円形にする*/
}

/*その他
---------------------------------------------------------------------------*/
.clearfix::after {
  content: "";
  display: block;
  clear: both;
}
.color-theme,
.color-theme a {
  color: #770000 !important;
}
.color-check,
.color-check a {
  color: #f00 !important;
}
.c {
  text-align: center !important;
}
.ws {
  width: 95%;
  display: block;
}
.wl {
  width: 95%;
  display: block;
}
.mb30 {
  margin-bottom: 30px !important;
}
.mt30 {
  margin-top: 30px !important;
}
.look {
  display: inline-block;
  padding: 0px 10px;
  background: #666;
  color: #fff;
  border-radius: 3px;
  margin: 5px 0;
  word-break: break-all;
}
.look .color-check {
  color: #ffcc00 !important;
}
.small {
  font-size: 0.6em;
}
.checkbox {
  display: none;
}
/*---------------------------------------------------------------------------
$スマホ用レスポンシブ対応
---------------------------------------------------------------------------*/
/*ヘッダー
---------------------------------------------------------------------------*/
@media screen and (max-width: 1000px) {
  video {
    margin-top: 35vh;
    object-fit: cover;
  }
  .copy {
    margin-top: 38vh;
  }
  header {
    margin: 0 0 2vw;
    position: fixed;
    width: 100%;
    height: 12vw;
  }
  header #logo {
    margin-top: 1vw;
    margin-left: 20vw;
    width: 40vw;
  }
  /* #menubar {
    width: 100vw;
    margin-top: 5vw;
    margin-right: -10vw;
  }
  #menubar ul {
    display: flex;
    margin-top: 4vw;
  }
  #menubar a {
    padding: 0 1.5vw;
    font-size: 3vw;
    white-space: nowrap;
    text-decoration: underline 0.3vw #ffffff;
  } */

  /*--------------------------*/
  html {
    scroll-behavior: smooth;
  }

  header {
    position: fixed;
    height: 12vw;
    width: 100%;
    background: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .hamburger {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    margin-top: 1vw;
    margin-left: 85vw;
  }

  .hamburger .bar {
    width: 6vw;
    height: 0.1px;
    background: #ffffff;
    border-radius: 2px;
    display: block;
    position: absolute;
    transition: all 0.3s;
  }

  .hamburger .bar-top {
    transform: translate(0, -10px);
  }

  .hamburger .bar-bottom {
    transform: translate(0, 10px);
  }

  @media screen and (max-width: 500px) {
    .hamburger {
      margin-top: 0px;
    }
    .hamburger .bar {
      width: 20px;
    }
    .hamburger .bar-top {
      transform: translate(0, -6px);
    }

    .hamburger .bar-bottom {
      transform: translate(0, 6px);
    }
  }

  .checkbox:checked ~ .hamburger .bar-middle {
    opacity: 0;
  }

  .checkbox:checked ~ .hamburger .bar-top {
    transform: translate(0, 0) rotate(45deg);
  }

  .checkbox:checked ~ .hamburger .bar-bottom {
    transform: translate(0, 0) rotate(-45deg);
  }

  .checkbox {
    display: none;
  }

  /* .nav-menu { */
  #menubar {
    position: fixed;
    width: 50vw;
    height: 100vh;
    background: #000;
    opacity: 0.8;
    color: #fff;
    top: 0;
    left: -100%;
    transition: all 0.5s;
  }

  /* .nav-menu ul { */
  #menubar ul {
    margin-top: 20vw;
    margin-bottom: 20vw;
  }

  #menubar li {
    margin-bottom: 20px;
  }

  #menubar a {
    text-align: center;
    font-size: 3vw;
  }

  .checkbox:checked ~ #menubar {
    left: 0;
  }

  /*TOP矢印
---------------------------------------------------------------------------*/
  .bg_Line {
    margin-top: 5vw;
    width: 0.4vw;
  }
  @keyframes bar-animation2 {
    0% {
      background-size: 0.4vw 0;
    }
    100% {
      background-size: 0.4vw 100%;
    }
  }

  /*main
---------------------------------------------------------------------------*/
  main {
    margin-top: 0.1vw;
  }

  main .top_text h2 {
    font-size: 5vw;
    /* text-decoration: underline 0.5vw #1a1a1a; */
    text-decoration: underline 1px #1a1a1a;
    text-underline-offset: 2px;
  }
  main h2.WH {
    /* text-decoration: underline 0.5vw #ffffff; */
    text-decoration: underline 1px #ffffff;
  }
  main h3 {
    margin: -2.8vw 0 0 0;
    font-size: 2vw;
    font-weight: lighter;
  }
  .top_text {
    margin-left: 5vw;
    z-index: 10;
    position: relative;
  }

  /*About
---------------------------------------------------------------------------*/
  .white_back {
    background-color: #ffffff;
    width: 100vw;
    height: 42vw;
    margin-top: 10vw;
    background-color: rgba(0, 0, 0, 0);
  }
  .about_logo_phone {
    position: absolute;
    display: inline;
    overflow: hidden;
    height: 41vw;
    margin-top: -46vw;
    margin-right: -20vw;
    background-color: #ffffff;
  }
  .about_logo_phone img {
    display: inline;
    object-fit: contain;
    object-position: 12vw -9.5vw;
    height: 60vw;
    margin-left: 40vw;
    opacity: 1;
  }
  /* .white_back_logo_phone {
    background-color: #ffffff;
    margin-top: -100vw;
    margin-left: 20vw;
    width: 50vw;
    height: 50vw;
    border-radius: 25vw;
  } */
  .About_img {
    /* width: 40vw;
    height: 40vw;
    display: flex;
    object-fit: cover;
    margin: -31vw 0 0 25.5vw; */
    opacity: 0;
    /* opacity: 0.5; */
  }
  .About_logo {
    /* margin: -28.5vw 0 0 20.5vw;
    width: 50vw; */
    opacity: 0;
  }
  .about_txt {
    margin-top: -2vw;
    padding-right: 4vw;
    font-size: 2.5vw;
    line-height: 3.5vw;
    width: 90vw;
    z-index: 20;
    position: relative;
  }
  @keyframes rotate-anime {
  }
  .animationSlow {
    visibility: visible;
    transform: translateY(0);
  }
  .activeSlow {
    visibility: visible;
    transform: translateY(0);
  }
  /*Our Busibess
---------------------------------------------------------------------------*/
  .black_back {
    height: 60vw;
  }
  .business_imges {
    margin-left: 5vw;
  }
  .business_section {
    display: block;
  }
  .img_B {
    width: 32vw;
    display: block;
  }
  .move_create {
    padding: 0;
    margin: 0;
    margin-bottom: 2vw;
  }
  .HP {
    padding: 0;
    margin: 0;
    margin-bottom: 2vw;
  }
  .consal {
    padding: 0;
    margin: 0;
    margin-bottom: 2vw;
  }
  .out_sorce {
    padding: 0;
    margin: 0;
    margin-bottom: 2vw;
  }
  .tx_B {
    font-size: 3vw;
    margin: -25vw 0 0 -14vw;
  }
  .p_B {
    font-size: 2.5vw;
    margin: -19vw 0 0 -14vw;
    width: 58vw;
    line-height: 3vw;
  }

  /*Works
---------------------------------------------------------------------------*/
  .white_back_works {
    margin-top: 90vw;
    margin-left: 0;
    width: 100vw;
    height: 40vw;
  }
  .top_text.works {
    margin-left: 5vw;
  }
  button.paginationColor {
    opacity: 0;
  }
  .slide-img {
    width: 35vw;
    height: 26vw;
  }
  /*Topics
---------------------------------------------------------------------------*/
  .topics_main {
    margin-top: 0vw;
    margin-left: -5.1vw;
  }
  .topics {
    margin-bottom: 2vw;
    width: 60vw;
    height: 4vw;
    font-size: 2.2vw;
  }
  .topicsTime {
    width: 13vw;
  }
  .topicsDesc {
    width: 47vw;
  }
  .topics_points {
    margin-top: 4vw;
    font-size: 2vw;
  }
  /*Contact
---------------------------------------------------------------------------*/
  .contact_main {
    font-size: 3.2vw;
    width: 30vw;
    margin: 3vw auto 0 34.5vw;
  }
  .contact_btn {
    margin-top: 9vw;
    margin-left: 30vw;
    font-size: 4vw;
    width: 30vw;
  }
  /*子ページ
---------------------------------------------------------------------------*/
  .phone_top {
    margin-top: 12vw;
  }
  .child_copy {
    top: 18vw;
    font-size: 6vw;
  }
  .Child_top {
    height: 30vw;
    width: 100%;
    object-fit: cover;
  }
  .bar {
    margin-left: 6vw;
  }
  .bar_top_line {
    height: 0.2vw;
  }
  .top_text_child_h2 {
    letter-spacing: 0.02em;
    text-decoration: underline 1px #ffffff;
    text-underline-offset: 4px;
    font-size: 5vw;
  }
  .top_text_child_h3 {
    margin: -2.5vw 0 0 0;
    color: #ffffff;
    font-size: 2vw;
  }
  .top_text_child_h2BL {
    letter-spacing: 0.02em;
    text-decoration: underline 1px #1a1a1a;
    text-underline-offset: 4px;
    font-size: 5vw;
  }
  .top_text_child_h3BL {
    margin: -2.5vw 0 0 0;
    color: #1a1a1a;
    font-size: 2vw;
  }
  .top_text_child_p {
    font-size: 2.5vw;
    line-height: 3vw;
  }

  /*子ページ About
---------------------------------------------------------------------------*/
  .white_back_about {
    margin-top: 5vw;
    height: 115vw;
    width: 100%;
    padding-right: 0;
  }
  .child_about {
    font-size: 3.5vw;
    margin-top: 3vw;
    padding-top: 5vw;
    padding-right: 0;
  }
  .child_p_about {
    font-size: 2.5vw;
    line-height: 5vw;
    padding-right: 0vw;
    margin: 0;
    width: 90vw;
  }
  .company_main {
    margin-left: -5vw;
  }
  .company {
    text-decoration: underline 1px rgba(255, 255, 255, 0.2);
    line-height: 5vw;
    text-underline-offset: 5px;
    font-size: 2.3vw;
  }
  /*子ページ Our Business
---------------------------------------------------------------------------*/
  .white_back_business {
    width: 100%;
    height: 350vw;
  }
  .left_child {
    margin: 15vw 0 15vw 5vw;
    width: 90vw;
  }
  .right_child {
    margin: 15vw 0 15vw 5vw;
    width: 90vw;
  }
  .top_text_child_h2_business {
    letter-spacing: 0.03em;
    text-decoration: underline 1px rgba(26, 26, 26, 0.5);
    text-underline-offset: 5px;
    font-size: 3.5vw;
  }
  .inline_right {
    margin-top: 68vw;
    margin-left: -91vw;
    width: 90vw;
  }
  .inline_left {
    margin-top: -15vw;
    padding-left: 5vw;
    width: 90vw;
  }
  /*子ページ Works
---------------------------------------------------------------------------*/
  #works {
    margin-top: 5vw;
    margin-left: 3vw;
  }
  .works_boxText img {
    width: 45vw;
    height: 40vw;
  }
  .worksHead {
    font-size: 2.5vw;
    width: 45vw;
    text-align: center;
  }
  .works_bar {
    opacity: 0;
  }
  /*子ページ Topics
---------------------------------------------------------------------------*/
  .white_back_topics {
    height: 80vw;
  }
  /*子ページ Contact
---------------------------------------------------------------------------*/
  .TopicsContact {
    height: 12vw;
  }
  .white_back_child_contact {
    height: 155vw;
  }
  input {
    margin: 0;
    width: 60vw;
  }
  textarea {
    margin: 0;
    margin-bottom: -2vw;
    width: 60vw;
  }
  .contact_box {
    margin-left: -12vw;
  }
  th {
    white-space: nowrap;
    font-size: 2.5vw;
    padding-top: 5vw;
  }
  td {
    padding-top: 5vw;
  }
  .go {
    margin: 8vw auto 0 43vw;
    font-size: 4vw;
    padding: 0 3vw 0;
  }
  .contact_table h3 {
    position: absolute;
    display: block;
    margin: 11vw 0 0 -62vw;
    font-size: 1.6vw;
  }
  .z_index_contact_phone {
    position: relative;
    z-index: 0;
  }
  /*詳細ページ(Works/Topics)
---------------------------------------------------------------------------*/
  .ommision {
    width: 55vw;
  }
  .videotopDetail {
    margin-top: 12vw;
  }
  .workDetail {
    padding: 5vw 5vw;
  }
  .workDetail img {
    width: 70vw;
    margin-left: 10vw;
  }
  .topicDetail {
    padding: 5vw 5vw;
  }
  .headline {
    font-size: 4vw;
    width: 90vw;
    line-height: 4vw;
  }
  .description {
    font-size: 2.5vw;
    width: 90vw;
    line-height: 4vw;
  }
  .bar_top_line.BL {
    margin-left: -5vw;
  }
  /*
---------------------------------------------------------------------------*/
}

/*---------------------------------------------------------------------------
ここから下は画面幅900px以上の追加指定
---------------------------------------------------------------------------*/
@media screen and (min-width: 1000px) {
  /*全体の設定
---------------------------------------------------------------------------*/
  html,
  body {
    font-size: 15px; /*基準となるフォントサイズの上書き*/
  }

  /*header（ロゴなどが入った最上段のブロック）
---------------------------------------------------------------------------*/
  /*ヘッダーブロック*/
  header {
    position: fixed;
    width: 100%;
  }

  /*menubarブロック設定
---------------------------------------------------------------------------*/
  /*メニューブロックの設定*/
  #menubar {
    font-size: 0.85em; /*文字サイズを少し小さくする*/
  }
  #menubar ul {
    display: flex; /*flexボックスを使う指定*/
  }

  /*メニュー１個あたりの設定*/
  #menubar a {
    padding: 10px 15px; /*上下、左右への余白*/
  }

  /*ドロップダウンメニュー
---------------------------------------------------------------------------*/
  #menubar .ddmenu_parent ul {
    position: absolute;
  }

  /*３本バー（ハンバーガー）アイコン設定
---------------------------------------------------------------------------*/
  /*ハンバーガーメニューを非表示にする*/
  #menubar_hdr {
    display: none;
  }

  /*「お知らせ」ブロック
---------------------------------------------------------------------------*/
  /*日付(dt)設定*/
  #new dt {
    width: 14em; /*幅。14文字(em)分。*/
    display: flex; /*flexボックスを使う指定*/
    justify-content: space-between; /*日付とアイコンをそれぞれ端に寄せる*/
  }

  /*日付の横のマーク（共通設定）*/
  #new dt span {
    display: inline-block; /*表示させる*/
    width: 6em; /*幅。6文字(em)分。*/
    background: #999; /*背景色*/
    color: #fff; /*文字色*/
    font-size: 0.8em; /*文字サイズを80%に。*/
    text-align: center; /*文字をセンタリング*/
    margin-right: 1em; /*アイコンの右側に空けるスペース*/
    align-self: flex-start; /*高さを間延びさせない指定*/
    line-height: 1.8; /*行間を少し狭く*/
    position: relative;
    top: 0.4em; /*上下の配置バランスの微調整*/
  }

  /*bg1設定。サンプルテンプレートでは「サービス」と書いてあるマーク*/
  #new dt span.icon-bg1 {
    background: #770000; /*背景色*/
  }

  /*記事(dd)設定*/
  #new dd {
    width: calc(100% - 14em); /*「14em」は上の「#new dt」のwidthの値です。*/
  }

  /*listブロック
---------------------------------------------------------------------------*/
  /*listブロック全体を囲むブロック*/
  .list-container {
    display: flex; /*flexボックスを使う指定*/
    flex-wrap: wrap; /*折り返す指定*/
    justify-content: space-between; /*並びかたの種類の指定*/
  }

  /*１個あたりのボックス設定*/
  .list {
    flex-direction: column; /*子要素を縦並びにする*/
    width: 32%; /*幅。３列になります。*/
  }

  /*ボックス内のfigure画像*/
  .list figure {
    width: 100%;
    margin-right: 0;
  }

  /*ボックス内のtextブロック*/
  .list .text {
    margin-right: 0;
  }

  /*ボックス内のh4タグ*/
  .list h4 {
    margin: 10px 0; /*上下、左右への余白*/
  }

  /*テーブル
---------------------------------------------------------------------------*/
  /*テーブル１行目に入った見出し部分（※caption）*/
  .ta1 caption {
    padding: 5px 15px; /*上下、左右へのボックス内の余白*/
  }

  /*th（左側）、td（右側）の共通設定*/
  .ta1 th,
  .ta1 td {
    padding: 20px 15px; /*上下、左右へのボックス内の余白*/
  }

  /*th（左側）のみの設定*/
  .ta1 th {
    width: 20%; /*幅*/
  }

  /*その他
---------------------------------------------------------------------------*/
  .ws {
    width: 48%;
    display: inline;
  }

  /*　※注意　下の閉じカッコ　}　はこのブロックに必要なので、削除しないで下さい。　*/
}
